import { Center, Spinner } from '@chakra-ui/react';
import { useSession, signOut } from 'next-auth/react';
import { useRouter } from '@/hooks/useRouter';
import { ReactNode, useEffect, useMemo } from 'react';
import { useUserContext } from '@/contexts/user-context';
import { Session } from '@/types/next-auth';
import { ACTIVE_STORE_ID_LOCAL_STORAGE_KEY } from '@/hooks/useStores';

const AuthGuard = ({ children }: { children: ReactNode }) => {
    const session = useSession();
    const { data: dataSession } = session as { data: Session | null };
    const { stores, loading } = useUserContext();
    const router = useRouter();

    const isAuthenticated = useMemo(() => session.status === 'authenticated', [session.status]);
    const isAuthRouteActive = useMemo(() => router.isLocatedIn('/auth'), [router]);
    const isAuthStateErrored = useMemo(
        () =>
            !!(
                (!session.data ||
                    !(session.data as unknown as Session)?.token ||
                    !(session.data as unknown as Session)?.user.actorType) &&
                session.status !== 'loading'
            ),
        [session],
    );
    const isLocatedInAuth = useMemo(() => router.isLocatedIn('/auth'), [router]);
    const userHasAStore = !loading && stores.length > 0;
    const redirectUrl = userHasAStore ? '/' : '/stores/awaiting-store-validation';

    useEffect(() => {
        if (isLocatedInAuth) {
            localStorage.removeItem(ACTIVE_STORE_ID_LOCAL_STORAGE_KEY);
        }
    }, [dataSession?.user.actorType, isLocatedInAuth]);

    useEffect(() => {
        if (loading) {
            return;
        }

        if (isAuthRouteActive && isAuthenticated) {
            router.push((router.query.redirect as string | undefined) ?? redirectUrl);
        } else if (!isAuthRouteActive && isAuthStateErrored) {
            localStorage.clear();
            signOut({ callbackUrl: `/auth/login?redirect=${router.asPath}` });
        }
    }, [
        dataSession?.user.actorType,
        isAuthRouteActive,
        isAuthStateErrored,
        isAuthenticated,
        isLocatedInAuth,
        loading,
        redirectUrl,
        router,
    ]);

    if ((isAuthRouteActive && isAuthenticated) || (!isAuthRouteActive && isAuthStateErrored)) {
        return (
            <Center bgColor="gray.100" w="100vw" h="100vh">
                <Spinner color="gray.600" size="xl" />
            </Center>
        );
    }
    return <>{children}</>;
};

export default AuthGuard;
