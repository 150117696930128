import { Box, BoxProps, Icon, Text, useToken } from '@chakra-ui/react';
import { t } from 'i18next';
import { FC } from 'react';
import { HiDatabase } from 'react-icons/hi';
import { PieChartTooltip } from '../tooltip/LabelValueTooltip';
import dynamic from 'next/dynamic';
const ResponsivePie = dynamic(() => import('@nivo/pie').then((module) => module.ResponsivePie), {
    ssr: false,
});

export interface IPieChartProps extends BoxProps {
    data: IPieData[];
    isFull?: boolean;
    disableLegend?: boolean;
    margin?: {
        top: number;
        right: number;
        bottom: number;
        left: number;
    };
    height?: string;
}

export interface IPieData {
    id: string;
    value: number;
    label: string;
    color: string;
}

export const PieChart: FC<IPieChartProps> = ({
    margin,
    data,
    height,
    disableLegend = true,
    isFull,
    ...boxProps
}) => {
    const fontFamily = useToken('fonts', 'body');
    const text2 = useToken('colors', 'text1');
    if (data.length === 0)
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDir="column"
                color="text2"
                height={height}
                gap={1}
                textAlign="center"
            >
                <Icon as={HiDatabase} fontSize="xl" />
                <Text fontSize="sm" fontWeight="medium">
                    {t('common:no_data')}
                </Text>
            </Box>
        );
    return (
        <Box width="100%" {...boxProps} h={height}>
            <ResponsivePie
                data={data}
                colors={data.map((item) => item.color)}
                margin={{ top: 6, right: 6, bottom: disableLegend ? 6 : 50, left: 6 }}
                startAngle={!isFull ? -90 : undefined}
                endAngle={!isFull ? 90 : undefined}
                innerRadius={0.6}
                sortByValue={true}
                padAngle={0.4}
                cornerRadius={4}
                activeInnerRadiusOffset={4}
                activeOuterRadiusOffset={4}
                theme={{
                    legends: {
                        text: {
                            fontSize: 12,
                            fontFamily: fontFamily,
                        },
                    },
                }}
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                }}
                legends={
                    disableLegend
                        ? undefined
                        : [
                              {
                                  anchor: 'bottom',
                                  direction: 'row',
                                  justify: false,
                                  translateX: 0,
                                  translateY: 50,
                                  itemWidth: 100,
                                  itemHeight: 20,
                                  itemTextColor: text2,
                                  itemDirection: 'left-to-right',
                                  itemOpacity: 1,
                                  symbolSize: 12,
                                  symbolShape: 'circle',
                              },
                          ]
                }
                tooltip={({ datum }) => <PieChartTooltip datum={datum} />}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={9}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                enableArcLabels={false}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                }}
            />
        </Box>
    );
};
