import { Flex, Stat, StatArrow, StatHelpText, StatNumber, Text } from '@chakra-ui/react';

interface IStatsCounterProps {
    countFrom: number;
    countTo: number;
    currency?: string;
    hidePercentage?: boolean;
}
const StatsCounter = (props: IStatsCounterProps) => {
    const countTo = props.countTo ? Math.round(props.countTo).toLocaleString('fr-FR') : '--';

    const diff = props.countFrom && props.countTo ? props.countTo - props.countFrom : null;
    let percentage = diff && props.countFrom ? (diff / props.countFrom) * 100 : null;
    percentage = percentage ? Math.round(percentage) : 0;

    return (
        <Stat w="auto" flexGrow={0}>
            <Flex>
                <StatNumber fontSize="5xl">{`${countTo} ${
                    props.currency ? props.currency : ''
                }`}</StatNumber>
                <StatHelpText mt={3} mx={1}>
                    {percentage && percentage > 0 ? (
                        <>
                            <Text color={'green.500'} mx={2}>
                                {percentage}%
                                <StatArrow type="increase" mx={1} />
                            </Text>
                        </>
                    ) : null}
                    {!props.hidePercentage && percentage && percentage < 0 ? (
                        <>
                            <Text color={'red.500'}>
                                {percentage}%
                                <StatArrow type="decrease" mx={1} />
                            </Text>
                        </>
                    ) : null}
                </StatHelpText>
            </Flex>
        </Stat>
    );
};

export default StatsCounter;
