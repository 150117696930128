import { HStack, Text } from '@chakra-ui/react';
import React from 'react';
import UserAvatar from './UserAvatar';

export type UserNameProps = {
    user: {
        name: string;
        id?: string;
    };
};

const UserName = ({ user }: UserNameProps) => {
    return (
        <HStack spacing={2}>
            <UserAvatar name={user.name} />
            <Text fontSize="md" fontWeight="medium">
                {user.name}
            </Text>
        </HStack>
    );
};

export default UserName;
