import { Box } from '@chakra-ui/react';
import { LineChart } from '../ui/charts/LineChart';
import SimpleCounter from './StatsCounter';
import { useQuery } from '@tanstack/react-query';
import api from '@/core/client';
import { useTranslation } from 'react-i18next';
import { SlicedTooltip } from '../ui/charts/tooltip/SlicedTooltip';
import { GetStorePremiumByDayDto, StoreDto } from '@/generated/typing';
import { daysList } from '@/utils/date';
import Title from '../ui/Title';
import { HiPresentationChartLine } from 'react-icons/hi2';
import { useColor } from '@/chakra/themeUtils';
import Panel from '@/components/ui/Panel';
import { differenceInDays, subDays } from 'date-fns';
import { useMemo } from 'react';

export interface IPremiumCardProps {
    startDate: Date;
    endDate: Date;
    activeStore?: StoreDto;
    selectedStoreIds?: string[];
}

export const PremiumCard = ({
    startDate,
    endDate,
    activeStore,
    selectedStoreIds,
}: IPremiumCardProps) => {
    const { t } = useTranslation(['common', 'dashboard']);
    const color = useColor('primary.400');

    const { data: dailyPremium } = useQuery({
        queryKey: [
            'stores',
            activeStore?.id ?? '',
            'getDailyPremium',
            startDate.toISOString(),
            endDate.toISOString(),
            selectedStoreIds,
        ],
        queryFn: () =>
            api.statistics.getStorePremiumByDay(activeStore!.id, {
                selectedStoreIds: selectedStoreIds ?? [],
            }),
        enabled: !!activeStore?.id && !!selectedStoreIds,
        keepPreviousData: true,
    });

    const { data: premium } = useQuery({
        queryKey: [
            'stores',
            activeStore?.id ?? '',
            'getPremium',
            startDate.toISOString(),
            endDate.toISOString(),
            selectedStoreIds,
        ],
        queryFn: () =>
            api.statistics.getPremiumByStore(activeStore?.id ?? '', {
                from: startDate.toISOString(),
                to: endDate.toISOString(),
                selectedStoreIds: selectedStoreIds ?? [],
            }),
        keepPreviousData: true,
        enabled: !!activeStore?.id && !!selectedStoreIds,
    });

    const daysDiff = differenceInDays(endDate, startDate);

    const { data: premiumComparisonPeriod } = useQuery({
        queryKey: ['stores', activeStore?.id ?? '', 'getPremiumLastMonth', selectedStoreIds],
        queryFn: () =>
            api.statistics.getPremiumByStore(activeStore!.id, {
                from: subDays(startDate, daysDiff).toISOString(),
                to: subDays(endDate, daysDiff).toISOString(),
                selectedStoreIds: selectedStoreIds ?? [],
            }),
        enabled: !!activeStore?.id && !!selectedStoreIds,
    });

    const labels = daysList(startDate, endDate);

    const lineData = useMemo(() => {
        return labels.map((label) => {
            const day = dailyPremium?.find(
                (item: GetStorePremiumByDayDto) => new Date(item.day).toISOString() === label,
            );
            return {
                x: label,
                y: day ? day.sum.toFixed(2) : 0,
            };
        });
    }, [dailyPremium, labels]);

    return (
        <>
            <Panel filled>
                <Title
                    title={t('dashboard:insurance_premium')}
                    IconComponent={HiPresentationChartLine}
                />
                <SimpleCounter
                    countTo={premium ?? 0}
                    countFrom={premiumComparisonPeriod ?? 0}
                    currency="€"
                />
                <Box style={{ height: '120px', width: '100%' }}>
                    <LineChart
                        data={[
                            {
                                id:
                                    selectedStoreIds?.join(',') +
                                        endDate.toISOString() +
                                        startDate.toISOString() ?? '',
                                label: t('dashboard:insurance_premium'),
                                data: lineData,
                                color: color,
                            },
                        ]}
                        margin={{ top: 0, right: 0, bottom: 5, left: 0 }}
                        tooltip={SlicedTooltip}
                    />
                </Box>
            </Panel>
        </>
    );
};
